import { Svg, SvgProps } from '@pancakeswap/uikit'

// NOTE: Temporary save icon here. Will move to uikit when the feature is done

const DisabledIcon = (props) => (
  <Svg id="arrow_loading" viewBox="0 0 24 24" {...props}>
    <path d="M19.5005 2.24916V6.74916C19.5005 6.94807 19.4215 7.13883 19.2808 7.27949C19.1402 7.42014 18.9494 7.49916 18.7505 7.49916H14.2505C14.0516 7.49916 13.8608 7.42014 13.7202 7.27949C13.5795 7.13883 13.5005 6.94807 13.5005 6.74916C13.5005 6.55024 13.5795 6.35948 13.7202 6.21883C13.8608 6.07818 14.0516 5.99916 14.2505 5.99916H16.8192L14.3264 3.71541L14.303 3.69291C13.2606 2.65087 11.9342 1.93908 10.4896 1.64648C9.04497 1.35388 7.54616 1.49342 6.1804 2.04769C4.81463 2.60195 3.64246 3.54636 2.81031 4.76293C1.97817 5.97951 1.52294 7.41431 1.50149 8.8881C1.48005 10.3619 1.89334 11.8093 2.68974 13.0496C3.48615 14.2899 4.63035 15.268 5.97941 15.8617C7.32847 16.4555 8.82258 16.6386 10.2751 16.3881C11.7276 16.1377 13.0741 15.4648 14.1464 14.4535C14.291 14.3168 14.484 14.2431 14.6829 14.2486C14.8819 14.2541 15.0705 14.3385 15.2072 14.4831C15.344 14.6276 15.4177 14.8206 15.4121 15.0196C15.4066 15.2185 15.3223 15.4071 15.1777 15.5438C13.5103 17.1252 11.2985 18.0043 9.0005 17.9992H8.87675C7.40275 17.979 5.95628 17.597 4.66446 16.8869C3.37265 16.1768 2.27504 15.1602 1.46811 13.9265C0.661184 12.6929 0.16964 11.2799 0.0366835 9.81174C-0.0962733 8.34361 0.133428 6.8653 0.705598 5.50674C1.27777 4.14818 2.17489 2.95096 3.31811 2.02031C4.46133 1.08966 5.81566 0.454066 7.26204 0.169401C8.70843 -0.115264 10.2026 -0.0402888 11.6132 0.387738C13.0239 0.815764 14.3077 1.58374 15.3521 2.62416L18.0005 5.04291V2.24916C18.0005 2.05024 18.0795 1.85948 18.2202 1.71883C18.3608 1.57817 18.5516 1.49916 18.7505 1.49916C18.9494 1.49916 19.1402 1.57817 19.2808 1.71883C19.4215 1.85948 19.5005 2.05024 19.5005 2.24916Z" fill="white"/>
    <defs>
      <path
        id="arrow"
        stroke="none"
        fill="none"
        d="M19.5005 2.24916V6.74916C19.5005 6.94807 19.4215 7.13883 19.2808 7.27949C19.1402 7.42014 18.9494 7.49916 18.7505 7.49916H14.2505C14.0516 7.49916 13.8608 7.42014 13.7202 7.27949C13.5795 7.13883 13.5005 6.94807 13.5005 6.74916C13.5005 6.55024 13.5795 6.35948 13.7202 6.21883C13.8608 6.07818 14.0516 5.99916 14.2505 5.99916H16.8192L14.3264 3.71541L14.303 3.69291C13.2606 2.65087 11.9342 1.93908 10.4896 1.64648C9.04497 1.35388 7.54616 1.49342 6.1804 2.04769C4.81463 2.60195 3.64246 3.54636 2.81031 4.76293C1.97817 5.97951 1.52294 7.41431 1.50149 8.8881C1.48005 10.3619 1.89334 11.8093 2.68974 13.0496C3.48615 14.2899 4.63035 15.268 5.97941 15.8617C7.32847 16.4555 8.82258 16.6386 10.2751 16.3881C11.7276 16.1377 13.0741 15.4648 14.1464 14.4535C14.291 14.3168 14.484 14.2431 14.6829 14.2486C14.8819 14.2541 15.0705 14.3385 15.2072 14.4831C15.344 14.6276 15.4177 14.8206 15.4121 15.0196C15.4066 15.2185 15.3223 15.4071 15.1777 15.5438C13.5103 17.1252 11.2985 18.0043 9.0005 17.9992H8.87675C7.40275 17.979 5.95628 17.597 4.66446 16.8869C3.37265 16.1768 2.27504 15.1602 1.46811 13.9265C0.661184 12.6929 0.16964 11.2799 0.0366835 9.81174C-0.0962733 8.34361 0.133428 6.8653 0.705598 5.50674C1.27777 4.14818 2.17489 2.95096 3.31811 2.02031C4.46133 1.08966 5.81566 0.454066 7.26204 0.169401C8.70843 -0.115264 10.2026 -0.0402888 11.6132 0.387738C13.0239 0.815764 14.3077 1.58374 15.3521 2.62416L18.0005 5.04291V2.24916C18.0005 2.05024 18.0795 1.85948 18.2202 1.71883C18.3608 1.57817 18.5516 1.49916 18.7505 1.49916C18.9494 1.49916 19.1402 1.57817 19.2808 1.71883C19.4215 1.85948 19.5005 2.05024 19.5005 2.24916Z"
      />
      <clipPath id="arrow-clip">
        <use xlinkHref="#arrow" />
      </clipPath>
    </defs>
  </Svg>
)

const Icon = ({ disabled, duration, ...props }: SvgProps & { disabled?: boolean; duration?: number | string }) =>
  disabled ? (
    <DisabledIcon {...props} />
  ) : (
    <Svg id="arrow_loading" viewBox="0 0 24 24" {...props}>
      <path
        stroke="none"
        fill="#7A6EAA"
        d="M19.5005 2.24916V6.74916C19.5005 6.94807 19.4215 7.13883 19.2808 7.27949C19.1402 7.42014 18.9494 7.49916 18.7505 7.49916H14.2505C14.0516 7.49916 13.8608 7.42014 13.7202 7.27949C13.5795 7.13883 13.5005 6.94807 13.5005 6.74916C13.5005 6.55024 13.5795 6.35948 13.7202 6.21883C13.8608 6.07818 14.0516 5.99916 14.2505 5.99916H16.8192L14.3264 3.71541L14.303 3.69291C13.2606 2.65087 11.9342 1.93908 10.4896 1.64648C9.04497 1.35388 7.54616 1.49342 6.1804 2.04769C4.81463 2.60195 3.64246 3.54636 2.81031 4.76293C1.97817 5.97951 1.52294 7.41431 1.50149 8.8881C1.48005 10.3619 1.89334 11.8093 2.68974 13.0496C3.48615 14.2899 4.63035 15.268 5.97941 15.8617C7.32847 16.4555 8.82258 16.6386 10.2751 16.3881C11.7276 16.1377 13.0741 15.4648 14.1464 14.4535C14.291 14.3168 14.484 14.2431 14.6829 14.2486C14.8819 14.2541 15.0705 14.3385 15.2072 14.4831C15.344 14.6276 15.4177 14.8206 15.4121 15.0196C15.4066 15.2185 15.3223 15.4071 15.1777 15.5438C13.5103 17.1252 11.2985 18.0043 9.0005 17.9992H8.87675C7.40275 17.979 5.95628 17.597 4.66446 16.8869C3.37265 16.1768 2.27504 15.1602 1.46811 13.9265C0.661184 12.6929 0.16964 11.2799 0.0366835 9.81174C-0.0962733 8.34361 0.133428 6.8653 0.705598 5.50674C1.27777 4.14818 2.17489 2.95096 3.31811 2.02031C4.46133 1.08966 5.81566 0.454066 7.26204 0.169401C8.70843 -0.115264 10.2026 -0.0402888 11.6132 0.387738C13.0239 0.815764 14.3077 1.58374 15.3521 2.62416L18.0005 5.04291V2.24916C18.0005 2.05024 18.0795 1.85948 18.2202 1.71883C18.3608 1.57817 18.5516 1.49916 18.7505 1.49916C18.9494 1.49916 19.1402 1.57817 19.2808 1.71883C19.4215 1.85948 19.5005 2.05024 19.5005 2.24916Z"
      />
      <defs>
        <path
          id="arrow"
          stroke="none"
          fill="none"
          d="M19.5005 2.24916V6.74916C19.5005 6.94807 19.4215 7.13883 19.2808 7.27949C19.1402 7.42014 18.9494 7.49916 18.7505 7.49916H14.2505C14.0516 7.49916 13.8608 7.42014 13.7202 7.27949C13.5795 7.13883 13.5005 6.94807 13.5005 6.74916C13.5005 6.55024 13.5795 6.35948 13.7202 6.21883C13.8608 6.07818 14.0516 5.99916 14.2505 5.99916H16.8192L14.3264 3.71541L14.303 3.69291C13.2606 2.65087 11.9342 1.93908 10.4896 1.64648C9.04497 1.35388 7.54616 1.49342 6.1804 2.04769C4.81463 2.60195 3.64246 3.54636 2.81031 4.76293C1.97817 5.97951 1.52294 7.41431 1.50149 8.8881C1.48005 10.3619 1.89334 11.8093 2.68974 13.0496C3.48615 14.2899 4.63035 15.268 5.97941 15.8617C7.32847 16.4555 8.82258 16.6386 10.2751 16.3881C11.7276 16.1377 13.0741 15.4648 14.1464 14.4535C14.291 14.3168 14.484 14.2431 14.6829 14.2486C14.8819 14.2541 15.0705 14.3385 15.2072 14.4831C15.344 14.6276 15.4177 14.8206 15.4121 15.0196C15.4066 15.2185 15.3223 15.4071 15.1777 15.5438C13.5103 17.1252 11.2985 18.0043 9.0005 17.9992H8.87675C7.40275 17.979 5.95628 17.597 4.66446 16.8869C3.37265 16.1768 2.27504 15.1602 1.46811 13.9265C0.661184 12.6929 0.16964 11.2799 0.0366835 9.81174C-0.0962733 8.34361 0.133428 6.8653 0.705598 5.50674C1.27777 4.14818 2.17489 2.95096 3.31811 2.02031C4.46133 1.08966 5.81566 0.454066 7.26204 0.169401C8.70843 -0.115264 10.2026 -0.0402888 11.6132 0.387738C13.0239 0.815764 14.3077 1.58374 15.3521 2.62416L18.0005 5.04291V2.24916C18.0005 2.05024 18.0795 1.85948 18.2202 1.71883C18.3608 1.57817 18.5516 1.49916 18.7505 1.49916C18.9494 1.49916 19.1402 1.57817 19.2808 1.71883C19.4215 1.85948 19.5005 2.05024 19.5005 2.24916Z"
        />
        <clipPath id="arrow-clip">
          <use xlinkHref="#arrow" />
        </clipPath>
      </defs>
      <g clipPath="url(#arrow-clip)">
        <circle
          cx="12"
          cy="12"
          r="5"
          transform="rotate(365,12,12)"
          fill="none"
          stroke="#D7CAEC"
          strokeWidth="16"
          strokeDasharray="30"
          strokeDashoffset="0"
        >
          <animate
            attributeName="stroke-dashoffset"
            values="0;-30"
            begin="arrow_loading.click; 0.7s"
            repeatCount="indefinite"
            dur={`${duration || 6.3}s`}
          />
        </circle>
      </g>
      <use xlinkHref="#arrow" />
      <animateTransform
        id="transform_0"
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        from="0 0 0"
        to="-10 0 0"
        dur="0.07s"
        begin="arrow_loading.click;"
        repeatCount="1"
      />
      <animateTransform
        id="transform_1"
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        from="-45 0 0"
        to="390 0 0"
        dur="0.6s"
        begin="transform_0.end"
        repeatCount="1"
      />
      <animateTransform
        id="transform_2"
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        from="390 0 0"
        to="360 0 0"
        dur="0.15s"
        begin="transform_1.end"
        repeatCount="1"
      />
    </Svg>
  )

export default Icon
